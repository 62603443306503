import React from "react";
import { AppStoreIcon, PlayStoreIcon } from "../assets/Icons";

const BenefitsSection = () => {
  return (
    <section className="text-white py-8">
      <div className="container mx-auto text-center" id="benefits">
        <h2 className="text-5xl sm:text-7xl font-bold mb-12 font-playfair">
          Benefits of Massage Key
        </h2>

        <div className="flex flex-col sm:flex-row justify-center items-start sm:space-x-16 font-poppins">
          {/* For Customers */}
          <div className="w-full md:w-1/3 mb-8 ">
            <div className="flex flex-col items-center">
              <div
                className="rounded-full overflow-hidden p-1"
                style={{
                  borderColor: "#C49755",
                  borderWidth: 3,
                  borderRadius: 100,
                }}
              >
                <img
                  src="https://s3-alpha-sig.figma.com/img/5efd/b9a1/85208415fc5d50afc633bc762ce7c5d6?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nH4zS88~X9mHpig3LT6QDEwHtKtQ07mhEAaz6gsIOmRBKeXY4YoZP5NBAOy6DP9VvObMBaUZCe9fV6NFKlCZPKc5JGG~R~EO8eSARFT25iV~2lx8dQOJ6C4TXdaohTMqCUs2VZRZz5HscyJwS2jzvLpALtq1ZwVLdMKZ~xjofFPS9JW7Tk9sSkVFiupX5cBR2Ti9jitBtyss0xiKE4zBOVS5iR62Fj7OO8azkdP0UmD8K2LvibJo7BkIBxa1zMHTXbbHuZcdzZszKNOLblwnlLsQ25go-Q4KQILBCTpmFjl~enuSU5E2RVShMptUiy9G95Nz22Ma~pU3fkAfqz4N~g__"
                  alt="For Customers"
                  className="w-48 h-auto  "
                />
              </div>

              <h3 className="text-2xl mt-5 font-semibold text-[#C49755]">
                <span className="text-white">FOR</span> <br /> CUSTOMERS
              </h3>
              <p className="text-lg mt-2">
                Discover New Services, <br /> Personalized Experiences, <br />
                And Save With Exclusive Offers.
              </p>
            </div>
          </div>

          {/* For Service Providers */}
          <div className="w-full md:w-1/3 mb-8">
            <div className="flex flex-col items-center">
              <h3 className="text-2xl font-semibold text-[#C49755]">
                <span className="text-white">FOR</span> <br /> SERVICE PROVIDERS
              </h3>
              <p className="text-lg mt-2">
                Increase Your Visibility, <br /> Manage Bookings Effortlessly,{" "}
                <br />
                And Grow Your Business.
              </p>
              <div
                className="rounded-full overflow-hidden p-1 mt-5"
                style={{
                  borderColor: "#C49755",
                  borderWidth: 3,
                  borderRadius: 100,
                }}
              >
                <img
                  src="https://s3-alpha-sig.figma.com/img/bbc5/b27d/246ccfeb804b16e1fa315b5cdec51eae?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fnMLerFvLXL5GCi~ggxvzOzQcCpJxG5m18rIE92Nfhg10yIiMKxHqH28E2y5gDPpDRODwwa~344hWbtIKTPAEM5OtxEk25nT2QrqBjAKFlqTbwpka2e-l~oA3Eq-pGSzPCyUNWX9Bw-303~OGqlx9~lVvbNC5UjdECp1u48qrswc2wMW8PQ~1tq8UIKFxewZCvXjj3G2SnU6uLBnlam-YyDo6cLhPFG9JJJwkqa9C7ozBCIA2adWddbd8bvasOAcFqMjZc~g2PnHr1tKTU8q1OAiI14HoJOXLYhskrCmUxVa2C5AoiPd81gwEZBmjeXVGrH82WCd3a-aNqszDwotDg__"
                  alt="For Service Providers"
                  className="w-48 h-auto"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Contact Us Section */}
        <div className="mt-24" id="contactus">
          <h2 className="text-5xl sm:text-7xl font-bold mb-8 font-playfair">
            Contact Us
          </h2>
          <div className="flex flex-col sm:flex-row justify-center items-center space-x-4">
            <div className="text-center sm:w-3/12">
              <p className="text-lg font-semibold">Choose Your Experience</p>
              <p className="text-lg">
                Select The Service And Therapist That Fits You Best
              </p>
            </div>
            <div className="text-center sm:w-3/12 my-4">
              <p className="text-lg font-semibold">Choose Your Experience</p>
              <p className="text-lg">
                Select The Service And Therapist That Fits You Best
              </p>
            </div>
            <div className="text-center sm:w-3/12">
              <p className="text-lg font-semibold">Choose Your Experience</p>
              <p className="text-lg">
                Select The Service And Therapist That Fits You Best
              </p>
            </div>
          </div>

          <div className="flex justify-center mt-8 space-x-4">
            <PlayStoreIcon />
            <AppStoreIcon />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
