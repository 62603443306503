import React from "react";
import { FeatureImage1, FeatureImage2 } from "../assets/Icons";

const FeaturesSection = () => {
  return (
    <section className="py-16 w-full flex flex-col justify-center items-center" id="features">
      <h2 className=" text-5xl sm:text-7xl text-white font-bold text-center font-playfair mb-12">
        Why Choose Our Platform?
      </h2>

      <div className="flex justify-center items-center sm:flex-row grid sm:grid-cols-2 gap-4">
        <div className="w-6/8">
          <FeatureImage1 width="35rem" />
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="w-1/2 md:w-2/4 mb-8 font-poppins">
            <h3 className="text-2xl font-semibold mt-4 text-[#C49755]">
              EASY BOOKING
            </h3>
            <p className="text-gray-300 mt-2 text-xl">
              Book Services Anytime, Anywhere With Our Intuitive Mobile App.
            </p>
          </div>
          <div className="w-1/2 md:w-2/4 mb-8 font-poppins">
            <h3 className="text-2xl font-semibold mt-4 text-[#C49755]">
              VERIFIED CENTERS
            </h3>
            <p className="text-gray-300 mt-2 text-xl">
              Only The Best Massage Centers, Verified And Rated By Real
              Customers.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center sm:flex-row grid sm:grid-cols-2">
        <div className="flex flex-col items-center justify-center">
          <div className="w-1/2 hidden sm:block md:w-2/4 mb-8 font-poppins">
            <h3 className="text-2xl font-semibold mt-4 text-[#C49755]">
              CUSTOM PREFERENCES
            </h3>
            <p className="text-gray-300 mt-2 text-xl">
              Tailor Your Massage Experience To Your Unique Needs.
            </p>
          </div>
          <div className="w-1/2 hidden sm:block md:w-2/4 mb-8 font-poppins">
            <h3 className="text-2xl font-semibold mt-4 text-[#C49755]">
              EXCLUSIVE DEALS
            </h3>
            <p className="text-gray-300 text-xl mt-2">
              Access Special Offers And Loyalty Rewards.
            </p>
          </div>
        </div>
        <div className="w-6/8">
          <FeatureImage2 width="40rem" />
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
