import React from "react";
import {
  AppStoreIcon,
  HowDoesItWork3,
  HowDoesWork1,
  HowDoesWork2,
  PlayStoreIcon,
} from "../assets/Icons";

const HowItWorksSection = () => {
  return (
    <section className="text-white py-16" id="howItWorks">
      <div className="mx-auto text-center">
        <h2 className="text-5xl sm:text-7xl font-bold font-playfair pb-0">
          How Does Massage Key Work?
        </h2>
        <div
          className="flex flex-col sm:flex-row justify-center items-center font-poppins"
          style={{ marginTop: "-2rem" }}
        >
          {/* Step 01 */}
          <div className="sm:w-1/3 mb-8 flex flex-col justify-center items-center">
            <div className="relative">
              <div className="sm:w-full h-auto object-cover rounded-lg">
                <HowDoesWork1 />
              </div>
            </div>
            <h3 className="text-2xl font-semibold mt-4 text-green-500 tracking-widest">
              STEP 01
            </h3>
            <p className="text-lg mt-2 w-2/3">
              <b>Search For Services</b> <br />
              Browse Top-Rated Massage Centers
            </p>
          </div>

          {/* Step 02 */}
          <div className="sm:w-1/3 sm:mt-32 flex flex-col justify-center items-center">
            <div className="relative">
              <div className="w-full h-auto object-cover rounded-lg">
                <HowDoesWork2 />
              </div>
            </div>
            <h3 className="text-2xl font-semibold mt-4 text-green-500 tracking-widest">
              STEP 02
            </h3>
            <p className="text-lg mt-2 w-2/3">
              <b>Choose Your Experience</b> <br />
              Select The Service And Therapist That Fits You Best
            </p>
          </div>

          {/* Step 03 */}
          <div className="sm:w-1/3 sm:mt-64 flex flex-col justify-center items-center">
            <div className="relative">
              <div className="w-full h-auto object-cover rounded-lg">
                <HowDoesItWork3 />
              </div>
            </div>
            <h3 className="text-2xl font-semibold mt-4 text-green-500 tracking-widest">
              STEP 03
            </h3>
            <p className="text-lg mt-2 w-2/3">
              <b>Book & Relax</b> <br />
              Book Your Appointment And Enjoy A Seamless Experience.
            </p>
          </div>
        </div>
        <p className="text-2xl sm:text-4xl mt-24 font-poppins uppercase  text-[#C49755] tracking-widest">
          Download The app and get started
        </p>
        <div className="flex flex-col sm:flex-row justify-center mt-6 space-x-4">
          <div className="flex items-center px-4 py-2 text-white rounded-lg">
            <PlayStoreIcon />
          </div>
          <div className="flex items-center px-4 py-2 text-white rounded-lg">
            <AppStoreIcon />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;
