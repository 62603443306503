import React from "react";
import HeroSection from "../components/HeroSection";
import FeaturesSection from "../components/FeatureSection";
import HowItWorksSection from "../components/HowDoesMasssgeWork";
import BenefitsSection from "../components/Benefits";

const Landing = () => {
  return (
    <div>
      <HeroSection />
      <FeaturesSection />
      <HowItWorksSection />
      <BenefitsSection />
    </div>
  );
};

export default Landing;
