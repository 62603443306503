import React, { useState } from "react";
import { Logo } from "../assets/Icons";
import { FiMenu, FiX } from "react-icons/fi"; // Using react-icons for menu icons

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <header className="flex justify-between items-center p-6">
      <div className="text-4xl font-bold text-white">
        <Logo />
      </div>

      {/* Hamburger Icon for Mobile */}
      <div className="md:hidden">
        <button onClick={toggleMenu} aria-label="Toggle Menu">
          {menuOpen ? (
            <FiX className="text-white text-3xl" />
          ) : (
            <FiMenu className="text-white text-3xl" />
          )}
        </button>
      </div>

      {/* Navigation Links */}
      <nav
        className={`${
          menuOpen ? "block" : "hidden"
        } md:flex space-x-0 md:space-x-12 text-white font-playfair absolute md:relative top-full left-0 w-full md:w-auto bg-[#8C6D3B] md:bg-transparent md:flex-row flex-col md:flex items-center mt-4 md:mt-0`}
      >
        <a href="/" className="hover:underline p-4 md:p-0">
          Home
        </a>
        <button onClick={() => handleScroll("features")}>
          <a href="#" className="hover:underline p-4 md:p-0">
            Features
          </a>
        </button>
        <button onClick={() => handleScroll("howItWorks")}>
          <a href="#" className="hover:underline p-4 md:p-0">
            How It Works
          </a>
        </button>
        <button onClick={() => handleScroll("benefits")}>
          <a href="#" className="hover:underline p-4 md:p-0">
            Benefits
          </a>
        </button>
        <button onClick={() => handleScroll("contactus")}>
          <a href="#" className="hover:underline p-4 md:p-0">
            Contact Us
          </a>
        </button>
      </nav>
    </header>
  );
}

export default Header;
