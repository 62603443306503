import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="container max-w-[1366px] text-white">     
        <h1>Privacy Policy</h1>
        <br />
        <br />
        <p>PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING THE SERVICE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED HEREIN AND ALL TERMS AND CONDITIONS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS SET FORTH BELOW, YOU MAY NOT USE the Massage key services.</p>
        <br />
        <br />
        <h2>Information We Collect</h2>
        <br />
        <br />
        <h3>Personal Information</h3>
        <br />
        <p>Personal Information is the type of information that specifically identifies or contacts a person, such as your full name or email address. We may collect personally identifiable information whenever Users enter into our app, fill out the form, to use our services, features or resources present in our app.</p>
        
        <p>In the following circumstances, we may disclose your personal information according to your wish or regulations by law:</p>
        <ul>
            <li>Your prior permission,</li>
            <li>By the applicable law within or outside your country of residence, legal process, litigation requests,</li>
            <li>By requests from public and governmental authorities,</li>
            <li>To protect our legal rights and interests.</li>
        </ul>
        <br />
        <h3>Non-Personal Information</h3>
        <br />
        <p>Non-personal information is data in a form that does not permit direct association with any specific individual, such as your install, uninstall, Android ID, phone IMEI number, phone model etc.</p>
        <br />
        <p>We may collect and use non-personal information in the following circumstances:</p>
        <ul>
            <li>To have a better understanding of a user's behavior.</li>
            <li>Solve problems in products and services.</li>
            <li>Improve our products, services, and advertising.</li>
        </ul>
        <br />
        <p>We may collect non-personal information such as the data of install, frequency of use, country and channel.</p>
        <br />
        <br />
        <h3>Information we get by using our services</h3>
        <br />
        <p>We may collect information about the services that you use and how you use them, such as when you view and interact with our content. We may collect device-specific information (such as your hardware model, OS version, and unique device identifiers). Massage key will not share that information with third parties.</p>
        <br />
        <h3>Location information</h3>
        <br />
        <p>When you use a location-enabled Massage key service, we may collect and process information about your actual location through GPS signals sent by a mobile device or Wi-Fi in order to obtain your location for the purposes of providing our Service. We may also use various technologies to determine location, such as sensor data from your device that may, for example, provide information on nearby Wi-Fi access points and cell towers.</p>
        <br />
        <h3>Unique application numbers</h3>
        <br />
        <p>Certain services include a unique application number. This number and information about your installation (for example, the operating system type) may be sent to Loan Massage key when you install or uninstall that service or when that service periodically contacts our servers, such as for automatic updates.</p>
        <br />
        <h3>What do we use your information for?</h3>
        <br />
        <p>Any general information we may collect from you may be used in one of the following ways:</p>
        <ul>
            <li>To personalize your experience, your information helps us to better respond to your individual needs.</li>
            <li>To improve our app.</li>
            <li>To provide the best Services to users.</li>
            <li>To improve customer service.</li>
        </ul>
        <br />
        <p>Your information will not be made available to anyone. Your information will not be sold, exchanged, transferred, or given to any other third party for any reason whatsoever, without your consent.</p>
        <br />
        <br />
        <h3>How do we use your information?</h3>
        <br />
        <p>ACCESS_NETWORK_STATE<br />
        Some of our applications may use this permission to access information about networks. In our apps, we will just give you information about the network, such as whether you are connected to a network or not. We don’t collect and share information with others.</p>
        <br />
        <p>ACCESS_COARSE_LOCATION <br />
        Some of our applications may use this permission to access approximate location derived from network location sources such as cell towers and Wi-Fi. This information is untraceable. We don’t collect and share location information with others.</p>
        <br />
        <p>ACCESS_FINE_LOCATION<br />
        Some of our applications may use this permission to access precise location derived from network location. This information is untraceable. We don’t collect and share location information with others.</p>
        <br />
        <p>WRITE_EXTERNAL_STORAGE and READ_EXTERNAL_STORAGE<br />
        Some of our applications may use these permissions to access Media files from USB storage. We don’t collect, store, or share information.</p>
        <br />
        <p>INTERNET<br />
        Some of our applications may use this permission to open network sockets. In our apps, we are using INTERNET permission to use the internet only. We don’t collect or transfer network information.</p>
        <br />
        <p>READ_PHONE_STATE<br />
        Some of our applications may use this permission to access phone state, including the phone number of the device, current cellular network information, the status of any ongoing calls, and a list of any phone accounts registered on the device. We don’t collect, store, or share any information.</p>
        <br />
        <p>CALL_PHONE<br />
        Some of our applications may use this permission to initiate a phone call without going through the Dialer to check the USSD codes. We do not collect or share this information.</p>
        <br />
        <p>READ_CONTACTS<br />
        Some of our applications may use this permission to read your contact information when you request. We do not collect or share this information. We use this permission to announce the caller's name or to display dialed/received calls/missed calls when you request.</p>
        <br />
        <p>READ_GSERVICES<br />
        Some of our applications may use (parts of) the Google Services Framework, it must declare the READ_GSERVICES permission. This information is untraceable.</p>
        <br />
        <p>VIBRATE<br />
        Some of our applications may use this permission to know vibration state and change vibration state when the user needs.</p>
        <br />
        <p>WAKE_LOCK<br />
        Some of our applications may use this permission to access Power Manager Wake Locks to keep processor from sleeping or screen from dimming when the user wants to display a digital clock on screen.</p>
        <br />
        <p>RECEIVE_BOOT_COMPLETED<br />
        Some of our applications may use this permission to allow alarm, Background services, etc., after booting has been completed.</p>
        <br />
        <p>SET_ALARM<br />
        Some of our applications may use this permission to access alarm from devices and sets.</p>
        <br />
        <p>SEND_SMS, READ_SMS, RECEIVE_SMS<br />
        Some of our applications may use these permissions to read, send or receive SMS from the device. We don’t collect, save or share your messages. This is highly secure.</p>
        <br />
        <p>SET_WALLPAPER<br />
        Some of our applications may use this permission to set a selected image as wallpaper.</p>
        <br />
        <p>SET_WALLPAPER_HINTS<br />
        Some of our applications may use this permission to adjust and resize selected wallpaper size.</p>
        <br />
        <p>ACCESS_WIFI_STATE<br />
        Some of our applications may use this permission to access information about Wi-Fi networks. We don’t collect or share any information about Wi-Fi.</p>
        <br />
        <p>CHANGE_WIFI_STATE<br />
        Some of our applications may use this permission to change Wi-Fi connectivity state (WIFI ON / WIFI OFF) when you request.</p>
        <br />
        <p>BLUETOOTH<br />
        Some of our applications may use this permission to connect paired Bluetooth devices when you request.</p>
        <br />
        <p>BLUETOOTH_ADMIN<br />
        Some of our applications may use this permission to discover and pair Bluetooth devices.</p>
        <br />
        <p>BLUETOOTH_PRIVILEGED<br />
        Some of our applications may use this permission to pair Bluetooth devices without user interaction, and to allow or disallow phonebook access or message access. We don’t save or share information.</p>
        <br />
        <p>WRITE_SECURE_SETTINGS<br />
        Some of our applications may use this permission to access device secure settings. We don’t collect or share information.</p>
        <br />
        <p>WRITE_SETTINGS<br />
        Some of our applications may use this permission to switch or adjust system settings such as ringtone, vibration and brightness of the screen, etc., when you request.</p>
        <br />
        <p>CAMERA<br />
        Some of our applications may use this permission to take photos/videos and turn ON/OFF Camera Flash, when you request. We do not save or upload your photos/videos.</p>
        <br />
        <p>FLASHLIGHT<br />
        Some of our applications may use this permission to turn ON/OFF Camera Flash. We use this permission for taking photos, flashlight on alert services or flashlight on clap services.</p>
        <br />
        <p>DISABLE_KEYGUARD<br />
        Some of our applications may use this permission to access our application functionality after the screen turned off.</p>
        <br />
        <p>RECORD_AUDIO<br />
        Some of our applications may use this permission to record an audio sound when you request. We don’t collect or share the information.</p>
        <br />
        <p>MODIFY_AUDIO_SETTINGS<br />
        Some of our applications may use this permission to modify global audio settings as your request.</p>
        <br />
        <p>BATTERY_STATS<br />
        Some of our applications may use this permission to access battery information such as battery percentage, capacity, etc., to display battery performance.</p>
        <br />
        <p>GET_ACCOUNTS<br />
        Some of our applications may use this permission to detect whether users have Google accounts or not, to confirm the state of Google Services, provide users with particular application download or update methods. We don’t share or save your account information in any manner. It is highly secure.</p>
        <br />
        <p>SYSTEM_ALERT_WINDOW<br />
        Some of our applications may use this permission to create windows and may show on top of other applications.</p>
        <br />
        <p>READ_CALL_LOG and WRITE_CALL_LOG<br />
        Some of our applications may use these permissions to display call logs from devices. We don’t collect, save, or share your call information. This is highly secure.</p>
        <br />
        <p>CLEAR_APP_CACHE<br />
        Some of our applications may use this permission to clear all the caches from all installed applications when you request.</p>
        <br />
        <p>KILL_BACKGROUND_PROCESSES<br />
        Some of our applications may use this permission to clear all the background processes from your device when you request.</p>
        <br />
        <p>GET_PACKAGE_SIZE<br />
        Some of our applications may use this permission to find out the space of used applications So, you can manage mobile space.</p>
        <br />
        <p>MOUNT_UNMOUNT_FILESYSTEMS<br />
        Some of our applications may use this permission to insert and remove file systems for external storage.</p>
        <br />
        <p>READ_SYNC_SETTINGS and WRITE_SYNC_SETTINGS<br />
        Some of our applications may use these permissions to allow SYNC settings for battery power saving.</p>
        <br />
        <p>RESTART_PACKAGES<br />
        Some of our applications may use this permission to kill Background Processes. It allows them to break other applications by removing their alarms, stopping their services, etc. We use these permissions to improve mobile performance when user requests.</p>
        <br />
        <p>REAL_GET_TASKS, GET_TASKS<br />
        Some of our applications may use this permission to kill Background Processes. It allows them to break other applications by removing their alarms, stopping their services, etc. We use these permissions to improve mobile performance when user requests.</p>
        <br />
        <br />
        <h3>Ad Networks and Cross Promotion Ads</h3>
        <br />
        <p>We welcome third-party ad networks for accepting advertisements (banners, interstitials, and video ads, etc...). In our apps and games, these advertisements are authorized to be displayed. Advertisers may use cookies and other web-tracking technologies to collect data in case the user clicks on any of these advertisements. We promote our own games, apps, and services in different types of ways. That might include cross-promoting one of our games or apps while you are playing a different game of ours. We display ads to cross-promote apps and games developed by us. We do not gather or share any of your personal identification information to display our ads.</p>
        <br />
        <br />
        <h3>Kids Privacy Policy</h3>
        <br />
        <p>This Kids Privacy Policy has to be specified to indicate you, with guidance regarding our privacy policies with respect to collecting, using, and disclosing personal information, regarding the legal guardian of a child under the age of 13 years old. Many of our Games/Apps are intended for general audiences, and we do not knowingly gather or use any Personal Information from children and kids under the age of 13. When users are identified as under 13, we will block such users from providing Personal Information or make sure to get prior parental consent before collecting Personal Information. If you are a parent of a child under 13 years of age and you think your child has provided us with Personal Information, please contact us. We offer mobile applications and games (Kids Apps), which are targeted to children under the age of 13 and also other apps are not targeted at children under the age of 13. If you have additional questions about our Privacy Practices related to children under the age of 13, please contact us.</p>
        <br />
        <br />
        <h3>Content Sharing</h3>
        <br />
        <p>You understand and agree that all Content that you have sent using our Services (For example data, sound, photographs, graphics, or video), whether publicly posted or privately sent, is the sole responsibility of the person that sent the Content. This means that you, not us, are entirely responsible for all Content that you may upload, communicate, transmit or otherwise make available through our Services.</p>
        <br />
        <br />
        <h3>Safeguards</h3>
        <br />
        <p>We store Personal information collected by us in secure operating environments that are not obtainable to the public and that are only accessible by authorized employees. We should also provide security measures in place to protect the loss, misuse, and alteration of the information under our control.</p>
        <br />
        <br />
        <h3>Changes to our Privacy Policy</h3>
        <br />
        <p>Massage key may, in its sole discretion, modify or update this Privacy Policy from time to time, and so you should review this page periodically. When we change the policy, we will update the 'last modified' date at the top of this page.</p>
        
        <p>In case you have any questions, concerns, or complaints regarding the processing of your Personal Information or this Privacy Policy you may write us on <a href="mailto:admin@massagekey.com">admin@massagekey.com</a>. We are committed to answering your questions within a reasonable time limit. Any delay in the resolution time shall be proactively communicated to you.</p>
        <br />
        <br />
        <p>Thank You</p></div>
    )
}

export default PrivacyPolicy;