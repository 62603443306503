import React from "react";
import { Logo } from "../assets/Icons";
function Footer() {
  const handleScroll = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  return (
    <footer className="text-white pt-8 bg-[#745B34]">
      <div className="mx-auto container px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Logo and Description */}
          <div className="text-center md:text-left mb-8 md:mb-0 w-3/12">
            <div className="flex flex-row items-center">
              <Logo />
              <h3 className="text-xl font-semibold font-playfair">
                Massage Key
              </h3>
            </div>
            <p className="mt-4 text-sm font-poppins">
            Massage Key is your go-to platform for discovering and booking the best spas and massages near you. We connect you with top-rated, verified massage centers, offering a seamless and personalized experience every time. Whether you're looking to relax, rejuvenate, or explore new services, Massage Key makes it easy to find what you need.
            </p>
          </div>

          {/* Quick Links */}
          <div className="text-center md:text-left mb-8 md:mb-0 font-poppins">
            <h4 className="text-lg font-semibold font-playfair">Quick Links</h4>
            <ul className="mt-4 space-y-2 text-sm cursor-pointer">
              <li onClick={()=> handleScroll('home')}>Home</li>
              <li onClick={() => handleScroll('features')} >Feature</li>
              <li  onClick={() => handleScroll('howItWorks')}>How it works</li>
              <li onClick={() => window.location.href = "/privacy-policy"}>Privacy Policy</li>
              <li  onClick={() => handleScroll('contactus')}>Contact Us</li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="text-center md:text-left font-poppins">
            <h4 className="text-lg font-semibold font-playfair">
              Contact Information
            </h4>
            <ul className="mt-4 space-y-2 text-sm">
              <li>Email - admin@massgekey.com</li>
            </ul>

            
          </div>
        </div>

        {/* Newsletter Subscription */}
        <div className="flex flex-col md:flex-row justify-center py-4 items-center mt-8 font-playfair">
          <p className="text-center md:text-left mb-4 md:mb-0 text-sm font-poppins">
            Subscribe to our newsletter for the latest updates and offers
          </p>
          <form className="flex mx-10 font-poppins">
            <input
              type="email"
              placeholder="Enter Your Email ID"
              className="py-2 px-4 rounded-l-full bg-white text-black"
            />
            <button
              type="submit"
              className="py-2 px-6 bg-green-500 text-white rounded-r-full"
            >
              Submit
            </button>
          </form>
        </div>

        {/* Footer Bottom */}
      </div>
      <div className="py-4 bg-[#1F3334] font-poppins">
        <p className="text-center text-sm">
          Copyright Massage Key | All Rights Reserved | 2024
        </p>
      </div>
    </footer>
  );
}

export default Footer;
