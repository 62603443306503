import React from "react";
import { AppStoreIcon, HeroImage, PlayStoreIcon } from "../assets/Icons";

function HeroSection() {
  return (
    <div className="text-center px-8 py-4" id="home">
      <div className="flex justify-center items-center flex-row grid sm:grid-cols-2 gap-4">
        <div className=" w-full flex align-center justify-center">
          <h1 className="text-5xl sm:text-7xl sm:w-2/3 w-2/3  font-bold self-end text-white font-playfair text-left leading-normal sm:leading-normal mb-4">
            Discover the Best Spas & Massages Near You
          </h1>
        </div>
        <div className="sm:overflow-hidden">
          <HeroImage />
        </div>
      </div>
      <p className="w-full text-2xl mt-8 font-poppins uppercase tracking-widest text-[#C49755]">
        Book your perfect spa day in just a few clicks
      </p>
      <div className="flex sm:flex-row flex-col justify-center items-center mt-6 sm:space-x-4">
        <div className="flex items-center px-4 py-2 text-white rounded-lg">
          <PlayStoreIcon />
        </div>
        <div className="flex items-center px-4 py-2 text-white rounded-lg">
          <AppStoreIcon />
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
