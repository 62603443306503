import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import FeaturesSection from "./components/FeatureSection";
import HowItWorksSection from "./components/HowDoesMasssgeWork";
import BenefitsSection from "./components/Benefits";
import Footer from "./components/Footer";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Landing from "./screens/Landing";
import PrivacyPolicy from "./screens/PrivacyPolicy";

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Landing />
      ),
    },
    {
      path: "privacy-policy",
      element: <PrivacyPolicy />,
    },
  ]);
  
  return (
    <div className="h-auto bg-[length:100%-100vh] bg-repeat bg-[#3E4F50] bg-blend-multiply  bg-[url('./assets/images/background.jpeg')]">
     <Header />
      <div className="flex flex-col w-full max-w-[1366px] mx-auto px-16 md:px-0 py-24 sm:py-32 overflow-x-hidden">
        
      <RouterProvider router={router} />
      </div>
      <Footer />
    </div>
  );
}

export default App;
